import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

function App() {

const [stateButton, setstateButton] = useState(true);
  useEffect(() => {
    
  }, [])

  const notifyError = () => toast.error("Some error occured", {
    position: "bottom-center",
    autoClose: 2500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
    theme: "colored",
    });

    const notifySuccess = () => toast.success("Got your response!!", {
      position: "bottom-center",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "colored",
      });

  async function submit(e){

let name = e.target[0].value;
let phone = e.target[1].value;
let message=  e.target[2].value;
setstateButton(false)
e.preventDefault();
let data = {name:name,phone:phone,message:message};
try {
  const response = await fetch("https://us-central1-lead-manager-final-product.cloudfunctions.net/app/contact", {
    method: "POST", // or 'PUT'
    body: JSON.stringify(data),
  });

  const result = await response.json();
  notifySuccess();
  console.log("Success:", result);
} catch (error) {
  notifySuccess();
  console.log("Error:", error);
}


  }

  return (
    <div>

<a href="https://api.whatsapp.com/send?phone=917395992766&text=Hi" class="float bounce2" target="_blank">
<i class="fa fa-whatsapp my-float"></i>
</a>

{/* <div class="cell">
    <div class="circle bounce2"></div>
    <p>bounce 2</p>
  </div> */}

    <div className="containerr">
     
     <div className='hero-wrapper'>
<div className='header'>

<div className='logo-container'><img src='/images/GL LOGO-new.png' className='logo'></img> </div>

<div className='gl-container'> <h2>A Product of Green Lime</h2> <img src='images/gl-logo.png' className='logo2'></img> </div>

</div>


<div className='hero-container'>


<div className='left-container'>
  <h4>Hi, Introducing</h4>

  
  <h3>Your personal Assistant for</h3>
  <ul>

    <li>Converting leads into customers</li>
    <li>Manage business contacts</li>
  </ul>

<a className="link-buttons" href='https://play.google.com/store/apps/details?id=com.greenlime.gl_lead_manager&hl=en_GB' target="_blank"><button className='link-buttons'>Install Now for free</button></a>
<a className="link-buttons" href='#contact'><button className='contact-button link-buttons'>Contact us</button></a>

</div>

<div className='right-container'>

{/* <img src='images/funnel-logo.png' className='hero-logo'></img> */}
<img src='images/hero.png' className='hero-image'></img>

</div>

</div>
<div className='background-blue'>


</div>

<div className='hero-container-mobile'>


<div className='left-container'>
  <h4>Hi, Introducing</h4>
<div className='background-wrapper'>
  <div className='background-st'>
  </div>
  <h3>Your personal Assistant for</h3>
  <ul>

    <li>Converting leads into customers</li>
    <li>Manage business contacts</li>
  </ul>

  </div>
  <div className='inner-container'>
  <img src='images/hero.png' className='hero-image'></img>
  <div className='inner-inner-container'>
  <a href='https://play.google.com/store/apps/details?id=com.greenlime.gl_lead_manager&hl=en_GB' target="_blank"><button>Install Now for free</button></a>
{/* <a href='#contact'><button className='contact-button'>Contact us</button></a> */}
</div>

{/* <div className='gl-container'> <h2>A Product of Green Lime</h2> <img src='images/gl-logo.png' className='logo2'></img> </div> */}

</div>
</div>



</div>
</div>


<div className='funnel-highlights-container'>

<h2>GL Funnel <span>Highlights</span></h2>

<div className='grid'>

<div className='item'>
<div className='number'>1</div>
<div className='image'> <img src='/images/highlight1.png'></img></div>
<div className='content'>
<b>Create Projects</b> and <b>Add your business contacts</b> under respective projects

</div>

</div>


<div className='item'>
<div className='number'>2</div>
<div className='image'> <img src='/images/highlight2.png'></img></div>
<div className='content'>

Take <b>multiple actions:</b> call, Message, Whats app and mail <b>from single lead screen</b>
</div>

</div>

<div className='item'>
<div className='number'>3</div>
<div className='image'> <img src='/images/highlight3.png'></img></div>
<div className='content'>

Note down and <b>track your conversations with customers</b> for future reference
</div>

</div>

<div className='item'>
<div className='number'>4</div>
<div className='image'> <img src='/images/highlight4.png'></img></div>
<div className='content'>
<b>Schedule Follow up call</b> or meeting with the customers & get reminders

</div>

</div>

<div className='item'>
<div className='number'>5</div>
<div className='image'> <img src='/images/highlight5.png'></img></div>
<div className='content'>
<b>Capture</b> your contact’s (customer) <b>reaction and character</b> to improve conversation
</div>

</div>

</div>

</div>

<div className='testimonial'>

<h2>What our client says</h2>


<div className='grid'>

<div className='item'>

<div className='cardd'>
  <img src='/images/inverted-commas 1.png'></img>
<spna style={{textAlign:"center"}}>"Our sales team was struggling to convert leads into customers efficiently. But ever since we started using GL Funnel, the game has changed! It's made lead management a breeze, and our conversion rates have soared. It's a must-have for any sales-driven business."</spna>

</div>

<div className='clients'>

<img src='/images/User Image 01.png'></img>
<span><b>Jane M</b></span>
<span>Sales Manager</span>
</div>

</div>

<div className='item'>
<div className='cardd'>
<img src='/images/inverted-commas white.png'></img>
  <span>"I run a small business, and every lead counts. GL Funnel has been a game-changer for me. I can now manage leads, track interactions, and follow up on potential customers from anywhere. It's like having a personal sales assistant in my pocket."</span>
</div>

<div className='clients'>

<img src='/images/User Image 02.png'></img>
<span><b>John Dale</b></span>
<span>Small Business Owner</span>
</div>

</div>

<div className='item'>
<div className='cardd'>
<img src='/images/inverted-commas 1.png'></img>
  <span>"As a marketing director, I'm always on the move, and GL Funnel has become my go-to tool. It not only helps me organize leads but also provides insights into lead behavior and preferences, making it easier to tailor our marketing efforts. It's incredibly valuable for driving conversions."</span>
</div>

<div className='clients'>

<img src='/images/User Image 03.png'></img>
<span><b>Saran H</b></span>
<span>Marketing Director</span>
</div>

</div>

</div>

</div>


<div className='features1'>

  <h2><img src='/images/shines.png'></img>Unique Features by GL</h2>

  {/* <div className='flexbox'>
  <ScrollAnimation animateIn="fadeInUp">
  <img src='/images/mobile new1.png'></img>
</ScrollAnimation>

<div className='right'>

<div className='number'>1</div>
<div className='box-card'>

<span className='content'>Capturing a leads character and<br></br>status using Emojis to have better<br></br> idea of the client before initiating a call.</span>

</div>
</div>


  </div> */}



<div className='flexbox'>

<div className='left'>
<ScrollAnimation animateIn="fadeInUp">
<img src='/images/mobile1-new.png'></img>
</ScrollAnimation>
</div>

<div className='right'>



<div className='number'>1</div>
<div className='box-card'>
<span className='content'>Capturing a leads character and status using Emojis to have better idea of the client before initiating a call.</span>

</div>

</div>


</div>


</div>

<div className='features2'>

<div className='left'>
<div className='number'>2</div>
<div className='box-card'>
<span className='content'>Sort all business contacts based on their conversion status, which will be used to create and visualise the conversion funnel of each project.</span>

</div>
</div>

<div className='right'>

<ScrollAnimation animateIn="fadeInUp">
<img src='/images/mobile2-quality-new.png'></img>
</ScrollAnimation>

</div>


</div>


<div className='features3'>

<div className='left'>

<ScrollAnimation animateIn="fadeInUp">
<img src='/images/mobile3-quality-new.png'></img>
</ScrollAnimation>

</div>


<div className='right'>

  <div className='number'>3</div>
<div className='box-card'>
<span className='content'>

Get the status card of a client during incoming call, with all the necessary information's Like

</span>

<ul>
<li>Customer/client Name</li>
<li>Project</li>
<li>Status of client</li>
<li>last call</li>
<li>Customer/client Character</li>
<li>Notes and reminders</li>

</ul>
</div>
</div>

</div>


<div className='contact-form' id='contact'>
<div className='wrapper'>
<h2>Contact Us</h2>

<form className='form' onSubmit={(e) => submit(e)}>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Name</label>
    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
    {/* <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> */}
  </div>
  <div class="mb-3">
    <label for="exampleInputPassword1" class="form-label">Phone Number</label>
    <input type="tel" class="form-control" id="exampleInputPassword1"/>
  </div>
  <div class="mb-3">
  <label for="exampleFormControlTextarea1" class="form-label">Message</label>
  <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
    {/* <label class="form-check-label" for="exampleCheck1">Check me out</label> */}
  </div>
  <button type="submit" disabled={!stateButton} class="btn btn-primary" >Submit</button>
</form>
      <ToastContainer />
</div>
</div>

<div className='footer'>

<div className='first-row'>
<div className='logo-container'><img src='/images/GL LOGO-new.png' className='logo'></img> </div>

</div>

<div className='second-row'>
<div className='gl-container'> <img src='images/gl-logo.png' className='logo'></img>  <h2>A Product of Green Lime</h2>

{/* <div className='social-icons'>
<img src='/images/insta-icon.png'>
</img>
<img src='/images/youtube-icon.png'>
</img>
</div> */}



</div>

{/* <div className='social-icons-mobile'>
<img src='/images/insta-icon.png'>
</img>
<img src='/images/youtube-icon.png'>
</img>
</div> */}

</div>
<div className='seperater'>

</div>
<div className='third-row'>
<span>Copyright © 2023 Green Lime</span>
</div>

</div>
    </div>

  
    </div>
  );
}

export default App;
